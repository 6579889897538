import Swiper, { Navigation } from "swiper";
import "swiper/swiper-bundle.min.css";
import MicroModal from "micromodal";
import IMask from "imask";
import intlTelInput from "intl-tel-input";
import "intl-tel-input/build/css/intlTelInput.css";
import "intl-tel-input/build/js/utils";

if (module.hot) {
  module.hot.accept();
}

document.querySelectorAll(".coursesItem")?.forEach((item) => {
  item.addEventListener("click", () => {
    item.classList.toggle("shown");
  });
});

const url = new URL(window.location.href);
const openModal = url.searchParams.get("open");

MicroModal.init({
  onShow: (modal) => {
    console.info(`${modal.id} is shown`);
  }, // [1]
  onClose: (modal) => console.info(`${modal.id} is hidden`), // [2]
  disableScroll: true, // [6]
  disableFocus: false,
});

if (openModal) {
  const offsetParent = document.getElementById("course").offsetTop;
  const scrollDiv = document.getElementById(openModal).offsetTop;
  window.scrollTo({ top: offsetParent + scrollDiv - 100, behavior: "smooth" });
  document.getElementById(openModal)?.classList.toggle("shown");
}

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const regexUTM = /^utm_/;
let stringUrlParams = urlParams;

for (let p of urlParams) {
  if (p[0].search(regexUTM) === -1) {
    stringUrlParams.delete(p[0]);
  }

  localStorage.setItem(p[0], p[1]);
}

function findLocalItems(query) {
  var i,
    results = [];
  for (i in localStorage) {
    if (localStorage.hasOwnProperty(i)) {
      if (i.match(query) || (!query && typeof i === "string")) {
        let value = localStorage.getItem(i);
        results.push({ key: i, val: value });
      }
    }
  }
  return results;
}

document.querySelectorAll(".button.buyBtn").forEach((item) => {
  item.addEventListener("click", (e) => {
    const utmItems = findLocalItems(regexUTM);
    let hrefStr = "";
    utmItems.forEach((item, index) => {
      hrefStr += (index !== 0 ? "&" : "?") + item.key + "=" + item.val;
    });
    item.href += hrefStr;
  });
});

const swiper = new Swiper(".swiper", {
  modules: [Navigation],
  loop: true,
  slideActiveClass: "reviewsBoxActive",
  navigation: {
    prevEl: ".btnPrev",
    nextEl: ".btnNext",
  },
  slideToClickedSlide: true,
  centeredSlides: true,

  breakpoints: {
    // when window width is >= 320px
    320: {
      slidesPerView: "auto",
      spaceBetween: 10,
    },
    768: {
      slidesPerView: "auto",
      watchSlidesVisibility: true,
      spaceBetween: 30,
    },
  },
});

function findAncestor(el, cls) {
  while ((el = el.parentElement) && !el.classList.contains(cls));
  return el;
}

window.addEventListener("click", (event) => {
  const parent = findAncestor(event.target, "programBox");

  if (parent) {
    parent.classList.toggle("hover");
  }

  const metrika = event.target.classList.contains("button");
  const newThemeClass = findAncestor(event.target, "newTheme");

  if (metrika && !newThemeClass) {
    ym(88005563, "reachGoal", "manager");
  }
});

async function postData(url = "", data = {}) {
  callbackForm.elements[2].disabled = true;
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
    },
    body: JSON.stringify({
      name: data.name,
      phone: data.phone,
      number_of_course: data.number_of_course,
      utm_source: data.utm_source,
      utm_medium: data.utm_medium,
      utm_campaign: data.utm_campaign,
      utm_content: data.utm_content,
      utm_term: data.utm_term,
    }),
  }).catch((error) => {
    // Your error is here!
    console.log(error);
  });

  return response.json();
}

async function sendToBitrix(event) {
  event.preventDefault();

  const formData = new FormData(event.target);
  const isChecked = formData.get("checkbox") === "on";

  const urlParams = new URLSearchParams(queryString);
  const utmSource = urlParams.get("utm_source");
  const utmMedium = urlParams.get("utm_medium");
  const utmCampaign = urlParams.get("utm_campaign");
  const utmContent = urlParams.get("utm_content");
  const utmTerm = urlParams.get("utm_term");

  if (!isChecked || !iti.isValidNumber()) {
  } else {
    try {
      const res = await postData("https://crmproxy.garantex.io", {
        phone: iti.getNumber(intlTelInputUtils.numberFormat.E164),
        name: formData.get("name"),
        number_of_course: 0,
        utm_source: utmSource,
        utm_medium: utmMedium,
        utm_campaign: utmCampaign,
        utm_content: utmContent,
        utm_term: utmTerm,
      });
      if (res.result) {
        document.querySelector(".callbackModalAccept").style = "display: flex;";
        callbackForm.reset();
        // phoneMask.reset();
        // IMask(phone, mask);
        checkForm();
        setTimeout(() => {
          document.querySelector(".callbackModalAccept").style =
            "display: none;";
          MicroModal.close("modal-callback");
        }, 1000);
      }
    } catch (error) {
      console.error("The Promise is rejected!", error);
    } finally {
      console.log(
        "The Promise is settled, meaning it has been resolved or rejected."
      );
    }
  }
}

const callbackForm = document.getElementById("callback");
callbackForm && callbackForm.addEventListener("submit", sendToBitrix);
callbackForm.addEventListener("change", checkForm);
const phone = document.querySelector("#phoneNum");

let iti = intlTelInput(phone, {
  initialCountry: "ru",
  autoPlaceholder: "polite",
  separateDialCode: true,
});
phone.addEventListener("change", checkForm);
phone.addEventListener("keyup", checkForm);

function checkForm() {
  const isValid = iti.isValidNumber();
  if (isValid && callbackForm.elements[0].value.length > 0) {
    callbackForm.elements[2].disabled = false;
  } else {
    callbackForm.elements[2].disabled = true;
  }
}
document.querySelector(".dropdownLi")?.addEventListener("click", (event) => {
  document.querySelector(".dropdown").classList.toggle("dropdownIsOpen");
});
